import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { authApi } from '@/entities/auth/api/authApi';
import { currencyDetails } from '@/shared/lib/constants/currency';
import { Currency } from '@/shared/types';

interface SettingsState {
  currency: Currency;
  isNavSidebarOpened: boolean;
}

export const settingsSliceName = 'settings';

const initialState: SettingsState = {
  currency: Currency.USD,
  isNavSidebarOpened: true,
};

const settingsSlice = createSlice({
  name: settingsSliceName,
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setIsNavSidebarOpened: (state, action) => {
      state.isNavSidebarOpened = action.payload;
    },
    toggleIsNavSidebarOpened: (state) => {
      state.isNavSidebarOpened = !state.isNavSidebarOpened;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(authApi.endpoints.checkUser.matchFulfilled),
      (state, action) => {
        const { currency } = action.payload.data;
        state.currency = currency;
      }
    );
  },
  selectors: {
    selectLocale: (state) => currencyDetails[state.currency].localization,
    selectCurrencySign: (state) => currencyDetails[state.currency].sign,
    selectIsNavSidebarOpened: (state) => state.isNavSidebarOpened,
  },
});

export const { toggleIsNavSidebarOpened, setIsNavSidebarOpened } =
  settingsSlice.actions;
export const { selectIsNavSidebarOpened, selectLocale, selectCurrencySign } =
  settingsSlice.selectors;

export default settingsSlice.reducer;
